import React, {useEffect, useState} from 'react';
import '../styles.css';
import { motion } from "framer-motion";
import '../App.css';
import { Link } from 'react-router-dom';
import logo from "../images/Frame 6.png"
import { useNavigate } from 'react-router-dom';
import { auth } from '../FirebaseConfig';
import blender from '../images/blender.jpg'; // Importa l'immagine
import santa from '../images/Santa.png'; // Importa l'immagine
import { onAuthStateChanged, signOut } from 'firebase/auth';
const Home: React.FC = () => {
  const navigate = useNavigate();
  const [logged, setlogged] = useState(false);
  const goToAboutPage = () => {
    navigate('/buy');
  };

  useEffect(() => {
    // Verifica che Firebase Authentication sia attivo e monitori lo stato di autenticazione
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log('Utente loggato:');
        setlogged(true)
   
      } else {
        console.log('Nessun utente loggato');
  
      }
    });

    return () => unsubscribe(); // Cleanup per evitare memory leak
  }, []);


const logout =  async ()  => {

signOut(auth);
}


  if (logged == false) {
    return (
      <div>request failed: not logged</div>
    )
   
    }

  return (
    <div className='flex flex-col h-screen  overflow-auto bg-home2  scrollbar-custom '  >
    <header className="flex     text-[1.3rem]  text-[#ECC5A0] font-imperial p-3 ">
      <div className='w-[30%] hidden sm:flex'>
      Giangibuilds
      </div>
      <div className='flex flex-1 items-center justify-center   text-[#8b8b8b] text-[0.7rem] space-x-3  '>
      <Link className='flex text-[#ffffff]   justify-center     font-inter ' to="/">
       
       Home
          
     
            
           
           </Link>
       
           <Link className=' flex justify-center     font-inter ' to="/Buy">
            
            Buy <span>&nbsp;</span><p className='hidden md:flex'> Models</p>
               
          
                 
                
                </Link>
                <Link className=' flex justify-center     font-inter ' to="/Cronology">
            Cronology <span>&nbsp;</span> <p className='hidden md:flex'>boughts</p>
                </Link>
                <Link className='flex  justify-center     font-inter ' to="/terms">
Terms
                </Link>
           </div>
    <div className='flex  sm:w-[30%] font-inter text-[0.8rem] items-center justify-end'>
   
        {auth.currentUser?.displayName}
        
        <div className='flex  font-inter '>
      <button onClick={logout} className='bg-[#A47040] ml-2 text-[0.7rem] py-1 text-white px-1 rounded-[5px] '>
        Log out
        </button>
    </div>
    </div>
    </header>
  
    {/* Scritta iniziale */}
    <div className='flex flex-col sm:flex-row items-center sm:items-start    justify-center  '>
    <div className='flex  flex-col     '>
      <div className='flex flex-col sm:items-center justify-center items-center md:pr-[2rem] md:w-[30rem]    flex-1   '>
  
 <img src={logo} alt="Logo" className='sm:flex w-[15rem] min-w-[320px]  '/> {/* Inserisci l'immagine */}
 <p className=' text-[2rem]  leading-[1.4rem] font-semibold      font-inter  text-[#E4D2D2]'>
 This is 
 </p>
 <motion.div
     initial={{ opacity: 0, y: 10 }} // Inizia invisibile e più in basso
     animate={{ opacity: 1, y: 0 }}   // Diventa visibile e si sposta verso l'alto
     transition={{ duration: 1 }}     // Imposta la durata dell'animazione
    >
      <p className='text-[2.9rem] leading-[3.7rem] font-semibold   font-inter tracking-[0.1rem]    text-[#ffcc8a]'>
 GIANGIBUILDS
 </p>
    </motion.div>

 <p className='text-[0.6rem]   font-inter  max-w-[30rem] st  text-center     text-[#A39C96]'>
 Giangibuilds is a platform that started with the idea of offering various services, such as the purchase of 3D models, scripts, and future features like bot creation and management. By logging in or registering, users automatically agree to the terms and conditions of the application.
 </p>

 <div className='flex    pt-5  '>
 <motion.div
      whileHover={{ scale: 1.05 }} // Quando si passa sopra, l'elemento aumenta di dimensione
      transition={{ type: "spring", stiffness: 400, damping: 50 }}
    >
       <a href='mailto:support@giangibuilds.com?subject=%5BInsert%20object%20of%20discussion%5D&body=%5BTell%20us%20what%20you%20need!%5D' className='bg-[#A47040] rounded-[6px] text-[0.7rem] px-6 mr-2 text-center text-white w-[7rem] py-[0.4rem]' >Contact me</a>
    </motion.div>
 
  <motion.div
      whileHover={{ scale: 1.05 }} // Quando si passa sopra, l'elemento aumenta di dimensione
      transition={{ type: "spring", stiffness: 400, damping: 50 }}
    >
            <button onClick={goToAboutPage} className='bg-transparent  rounded-[6px] text-[0.7rem] text-white px-6 py-[0.3rem] border-[1px] border-[#A7A7A7]'>Buy models</button>
    </motion.div>
  
 </div>
 </div>



 
    </div>

     </div>
       {/* divisore */}
     <div className='flex pt-4 sm:pt-16  px-10 items-center '>
   <div className='flex flex-1 bg-[#6d6d6dc7] h-[1px] '></div>
  
   
     </div>
       {/* parte sotto */}
       <div className='flex justify-center    py-10 '>
        <div className=' items-center hidden md:flex '>
        <motion.div
      whileHover={{ scale: 1.1 }} // Quando si passa sopra, l'elemento aumenta di dimensione
      transition={{ type: "spring", stiffness: 400, damping: 50 }}
    >
         <img src={blender} alt="Logo" className='w-[30rem] min-w-[480px] brightness-[90%] pl-[64px] '/> {/* Inserisci l'immagine */}
    </motion.div>
       
        </div>
       <div className='flex flex-col items-center md:items-start  pt-3   '>
  
  <p className=' text-[2rem]  leading-[1.4rem] font-semibold      pl-0    md:pl-[4rem]  font-inter m-0 p-0 text-[#E4D2D2]'>
   What can we do?
  </p>
 
  <p className='text-[2.2rem] leading-[3.7rem] font-semibold   font-inter     pl-0   md:pl-[4rem] text-[#ECC5A0]'>
   Everything you need
  </p>

  <p className='text-[0.6rem] sm:pr-0  pr-10 pl-10  font-inter  max-w-[30rem]  sm:text-left  text-center     md:pl-[4rem] text-[#A39C96]'>
  “ Throughout my career, I have created a wide variety of models, both realistic and low poly, for Roblox Studio and Blender. I have also developed numerous scripts for Roblox and various applications, and I am a full-stack developer specializing in website and app development. In fact, this website was entirely built by me using React and Firebase! “   
  </p>
 
 
  <div className='flex  md:pl-[4rem]   pt-5  '>
 <motion.div
      whileHover={{ scale: 1.05 }} // Quando si passa sopra, l'elemento aumenta di dimensione
      transition={{ type: "spring", stiffness: 400, damping: 50 }}
    >
       <a href='mailto:support@giangibuilds.com?subject=%5BInsert%20object%20of%20discussion%5D&body=%5BTell%20us%20what%20you%20need!%5D' className='bg-[#A47040] rounded-[6px] text-[0.7rem] px-6 mr-2 text-center text-white  py-[0.4rem]' >Contact me</a>
    </motion.div>
 
  <motion.div
      whileHover={{ scale: 1.05 }} // Quando si passa sopra, l'elemento aumenta di dimensione
      transition={{ type: "spring", stiffness: 400, damping: 50 }}
    >
      <button onClick={goToAboutPage} className='bg-transparent  rounded-[6px] text-[0.7rem] text-white px-6 py-[0.3rem] border-[1px] border-[#A7A7A7]'>Buy models</button>
    </motion.div>
  
 </div>
  </div>
       </div>
   
        {/* parte sotto 2 */}
       
        <div className='flex justify-center flex-col md:flex-row md:items-start  items-center   py-0  md:py-10 '>
        <motion.div
      whileHover={{ scale: 1.2 }} // Quando si passa sopra, l'elemento aumenta di dimensione
      transition={{ type: "spring", stiffness: 400, damping: 10 }}
    >
         <img src={blender} alt="Logo" className='w-[23rem] md:hidden min-w-[320px] brightness-[90%] pb-10  '/> {/* Inserisci l'immagine */}
    </motion.div>

        <div className=' flex-col  ml-0  md:ml-[64px] items-center md:items-start  hidden md:flex   pt-5   '>
  
  <p className=' text-[2rem]  leading-[1.4rem] font-semibold    font-inter m-0 p-0 text-[#E4D2D2]'>
  Explore the stuffs
  </p>
 
  <p className='text-[2.2rem] leading-[3.7rem] font-semibold   font-inter     text-[#ECC5A0]'>
   That we made for you
  </p>
  <p className='text-[0.6rem] px-10   md:pr-0 md:pl-0   md:text-left  text-center font-inter  max-w-[30rem]    text-[#A39C96]'>
  

As mentioned earlier, we have created numerous models and scripts, and we decided to build a website where we sell everything we have made. For more information on the terms of service and privacy policy of this website, click the Terms button.
  </p>
 
  <div className=' flex pt-5 space-x-3'>
  <a href='mailto:support@giangibuilds.com?subject=%5BInsert%20object%20of%20discussion%5D&body=%5BTell%20us%20what%20you%20need!%5D' className='bg-[#A47040] rounded-[6px] text-[0.7rem] text-center text-white w-[7rem] py-[0.4rem]' >Contact me</a>
  <button onClick={() => navigate("/terms")} className='bg-transparent  rounded-[6px] text-[0.7rem] text-white  w-[7rem] py-[0.3rem] border-[1px] border-[#A7A7A7]'>Terms</button>
  </div>
  </div>
        <img src={santa} alt="Logo" className='w-[26rem] md:block hidden   brightness-[90%] pr-0 md:pr-[20px]'/> {/* Inserisci l'immagine */}
       </div>
       <div className='flex justify-center mt-2 text-white text-[0.6rem] '>
       © 2025 Giangibuilds from Gianluigi Izzo
    </div>
    </div>
  );
}

export default Home;


