import React, {useEffect, useState} from 'react';
import {Firestore, Timestamp, collection, doc, getDocs, onSnapshot, where, query} from 'firebase/firestore'
import '../styles.css';
import { firestore } from '../FirebaseConfig'; 
import '../App.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Order from '../objects/Order';
import blender from '../images/blender.jpg'; // Importa l'immagine
import { storage } from "../FirebaseConfig";
import options from './altristili';
import { auth} from '../FirebaseConfig';
import { format, toDate } from 'date-fns';
import { time } from 'console';
import { onAuthStateChanged, signOut } from 'firebase/auth';

interface Model {
id: string,
timestamp: string,
transactionId: string,
}
interface data {
  Nome: string,
  Descrizione: string,
  Prezzo: string,
  immagine: string,
  Categoria: string,
  data: string,
}
const Cronology: React.FC = () => {

  const [orders, setorders] = useState<Model[]>([]);
  const [data, setdata] = useState<data | null>(null);
  const navigate = useNavigate();


  const convert = (timestamp: Timestamp): string=> {
    const date = timestamp.toDate();
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Mese inizia da 0
    const year = date.getFullYear();
   const datafinale = `${day}/${month}/${year}`;
  return datafinale
  }


const handleOrderClick = (data: object) => {
try{
  const { id } = data as { id: string };
  const { timestamp } = data as { timestamp: string };
  const ModelsCollection = collection(firestore, "Modelli");
  const modelsQuery = query(ModelsCollection, where("Storagepath", "==", id));

  // Ascolta i cambiamenti in tempo reale nella collezione "Modelli"
  const unsubscribe = onSnapshot(modelsQuery, async (snapshot) => {
    // Verifica se ci sono documenti che corrispondono alla query
    if (!snapshot.empty) {
      const doc = snapshot.docs[0]; // Ottieni il primo documento trovato
      const imageRef = ref(storage, `Modelli/${doc.id}/${doc.id}.jpg`);
      const imageUrl = await getDownloadURL(imageRef);
      // Imposta l'oggetto info con i dati del documento
      setdata({
        Nome: doc.data()?.Nome || "Nome non disponibile",
        Descrizione: doc.data()?.Descrizione || "Categoria non disponibile",
        Prezzo: doc.data()?.Prezzo || "Categoria non disponibile",
        immagine: imageUrl || "Categoria non disponibile",
        Categoria: doc.data()?.Categoria || "Categoria non disponibile",
        data: timestamp,
      });
    } else {
      setdata(null); // Se non ci sono documenti, imposta info a null
    }
  }, (error) => {
    console.error("Errore nell'ascoltare i modelli:", error);
  });

  // Cleanup: chiude la connessione quando il componente viene smontato
  return () => unsubscribe();


   
 


  
} catch (error) {

}
}



//getordini
useEffect(() => {
  const user = auth.currentUser?.uid
  
  if (!user) {
    console.error("Utente non autenticato o displayName mancante");
    return;
  }
  console.log(user)
  const ModelsCollection = collection(firestore, "utenti", user, "ordiniubuy");

  // Ascolta i cambiamenti in tempo reale nella collezione "Modelli"
  const unsubscribe = onSnapshot(ModelsCollection, async (snapshot) => {
    const orders = await Promise.all(snapshot.docs.map(async (Doc) => {
      return {
      id: Doc.id,
      timestamp:  convert(Doc.data()?.timestamp),
      transactionId: Doc.data()?.transactionId || "Nome non disponibile",
   };
   }));
    setorders(orders); // Aggiorna lo stato con i modelli aggiornati
  }, (error) => {
    console.error("Errore nell'ascoltare i modelli:", error);
  });

  // Cleanup: chiude la connessione quando il componente viene smontato
  return () => unsubscribe();
}, []);

const logout =  async ()  => {

  signOut(auth);
  }

  return (
    <div className='flex flex-col h-screen  overflow-auto bg-home2  scrollbar-custom px-2 '  >
     <header className="flex     text-[1.3rem]  text-[#ECC5A0] font-imperial p-3 ">
     <div className='w-[30%] hidden sm:flex'>
      Giangibuilds
      </div>
              <div className='flex flex-1 items-center justify-center   text-[#8b8b8b] text-[0.7rem] space-x-3  '>
              <Link className='flex    justify-center     font-inter ' to="/">
               
               Home
                  
             
                    
                   
                   </Link>
               
                   <Link className=' flex  justify-center     font-inter ' to="/Buy">
                    
                    Buy <span>&nbsp;</span><p className='hidden md:flex'> Models</p>
                       
                  
                         
                        
                        </Link>
                        <Link className=' flex text-[#ffffff] justify-center     font-inter ' to="/Cronology">
                    Cronology <span>&nbsp;</span> <p className='hidden md:flex'>boughts</p>
                        </Link>
                        <Link className='flex  justify-center     font-inter ' to="/terms">
        Terms
                        </Link>
                   </div>
            <div className='flex  sm:w-[30%] font-inter text-[0.8rem] items-center justify-end'>
           
                {auth.currentUser?.displayName}
                
                <div className='flex  font-inter '>
              <button onClick={logout} className='bg-[#A47040] ml-2 text-[0.7rem] py-1 text-white px-1 rounded-[5px] '>
                Log out
                </button>
            </div>
            </div>
            </header>
<div className='flex flex-col sm:flex-row pt-4 justify-center'>
  <div className='flex flex-col'>
<p className='text-white text-[0.9rem] font-thin font-inter'>Cronology boughts</p>
<div className='flex flex-col border-[1px]  h-[25rem] bg-[#0000004b] rounded-[5px] border-[#818080] text-white'>

<div className='flex flex-row justify-center text-[0.7rem] border-b-[1px] border-[#818080]  font-thin'>
<p className='w-[5rem] sm:w-[5rem] md:w-[rem] lg:w-[7rem] xl:w-[9rem]  text-center'>Title of order</p>
<p  className='w-[5rem] sm:w-[5rem] md:w-[rem] lg:w-[7rem] xl:w-[9rem] text-center'>Type</p>
<p className='w-[5rem] sm:w-[5rem] md:w-[rem] lg:w-[7rem] xl:w-[9rem] text-center'>Date</p>
<p className='w-[5rem] sm:w-[5rem] md:w-[rem] lg:w-[7rem] xl:w-[9rem] text-center'>State</p>
</div>
<div className='flex flex-col overflow-y-scroll scrollbar-custom'>
{orders.map((model) => (
  // Verifica se il prezzo del modello è maggiore di 30

    <Order
      key={model.id}
      id={model.id} // Usare una chiave univoca per ogni elemento in lista
      timestamp={model.timestamp}
      transactionId={model.transactionId}
      onClick={() => handleOrderClick(model)}
    />
 
))}

</div>






</div>
</div>
{ data? 
  <div className='flex flex-col w-[100%] sm:w-[13rem] border-[1px] px-2 sm:ml-2 mt-2 sm:mt-0 rounded-[5px] border-[#818080]  items-center '>
<div className='flex  w-[10rem] bg-slate-100 rounded-[5px] mt-[2rem] '>
<img
        src={data.immagine} // sostituisci con il tuo URL dell'immagine
        alt="example"
        className=" rounded-[5px] "
      />
</div>
<div className='flex-1'>
<p className='flex text-[0.8rem]  justify-center    pt-2  md:text-left  text-center font-inter      text-[#ffffff]'>
{data.Nome}
</p>

<p className='flex text-[0.5rem]  justify-center    pt-1  pb-2 md:text-left  text-center font-inter      text-[#c0c0c0]'>
{data.Descrizione}
</p>

<div className='flex   items-center '>
 <div className='flex flex-1 bg-[#6d6d6dc7] h-[1px] '></div>
   </div>
   <div className='flex flex-row'>
<p className='flex flex-1 text-[0.6rem]  text-start      p-[0.4rem] md:text-left   font-inter    text-[#aaaaaa]'>
Name
</p>
<p className='flex text-[0.6rem]   justify-center      p-[0.4rem] md:text-left  text-center font-inter      text-[#ffffff]'>
{data.Nome}
</p>
</div>
<div className='flex flex-row'>
<p className='flex flex-1 text-[0.6rem]  text-start      p-[0.4rem] md:text-left   font-inter    text-[#aaaaaa]'>
Date of payment
</p>
<p className='flex text-[0.6rem]   justify-center      p-[0.4rem] md:text-left  text-center font-inter      text-[#ffffff]'>
{data.data}
</p>
</div>

<div className='flex flex-row'>
<p className='flex flex-1 text-[0.6rem]  text-start     p-[0.4rem] md:text-left   font-inter      text-[#aaaaaa]'>
Category
</p>
<p className='flex text-[0.6rem]   justify-center      p-[0.4rem] md:text-left  text-center font-inter      text-[#ffffff]'>
{data.Categoria}
</p>
</div>
<div className='flex flex-row'>
<p className='flex flex-1 text-[0.6rem]  text-start     p-[0.4rem] md:text-left   font-inter      text-[#aaaaaa]'>
Price
</p>
<p className='flex text-[0.6rem]   justify-center      p-[0.4rem] md:text-left  text-center font-inter      text-[#ffffff]'>
{data.Prezzo + " €"}
</p>
</div>
<div className='flex flex-row'>
<p className='flex flex-1 text-[0.6rem]  text-start     p-[0.4rem] md:text-left   font-inter      text-[#aaaaaa]'>
State
</p>
<p className='flex text-[0.6rem]   justify-center      p-[0.4rem] md:text-left  text-center font-inter      text-[#ffffff]'>
Completed
</p>
</div>



<div className='flex   items-center '>
 <div className='flex flex-1 bg-[#6d6d6dc7] h-[1px] '></div>
   </div>

  




    

</div>
<div className='flex  pb-2 justify-center items-end'>
<button  className='bg-[#bb2e2e] rounded-[6px] px-5 text-[0.7rem] text-white mt-4    py-[0.15rem]'>Ask for refund</button>
</div>
</div>
: 
<div className='flex flex-col border-[1px] px-[2.4rem] justify-center text-[#bebebe] ml-2 rounded-[5px] border-[#818080]  items-center '>
  Nothing selected...
</div>
}

</div>

    <div className='flex flex-1 items-end justify-center mt-2 text-white text-[0.6rem] '>
    © 2025 Giangibuilds from Gianluigi Izzo
    </div>
  </div>
   
  );
}
export default Cronology;


