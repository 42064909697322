import React, {useEffect, useState} from 'react';
import '../styles.css';
import '../App.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { auth } from '../FirebaseConfig';
import { onAuthStateChanged, createUserWithEmailAndPassword, UserCredential, updateProfile, signInWithEmailAndPassword } from 'firebase/auth';
import { response } from 'express';
import { set } from 'date-fns';
import { isMobile } from 'react-device-detect';
function Register() {
  const navigate = useNavigate();
  const [logged, setlogged] = useState(false);
  const goToAboutPage = () => {
    navigate('/login');
  };
  const [errore, seterrore] = useState(false)
  const [loading, setloading] = useState(false)
  const [erroremess, seterroremess] = useState("")
  const [inputValue, setInputValue] = useState({
    nome: "",
    cognome: "",
    email: "",
    password: "",
    password_confirm: "",
  });
  
  if (logged == true) {
    return (
      <div>request failed: already logged in</div>
    )
    }
    const handleRegister = async (event: React.FormEvent) => {
      event.preventDefault();
      if (inputValue.password !== inputValue.password_confirm) {
        seterroremess("Passwords does't match");
        return
      }
      try {
        // Chiamata Firebase per creare un nuovo utente
        setloading(true)
        const apiClient = process.env.REACT_APP_APICLIENT;
        const response = await axios.post(apiClient? apiClient: "invalid", {
          action: 'createuser',
          Nome:  inputValue.nome,
          email: inputValue.email,
          password: inputValue.password
        }); 
       console.log("succ")
       if (response.data.success) {
       setloading(false)
       signInWithEmailAndPassword(auth, inputValue.email, inputValue.password)
       }else
       {
        setloading(false)
       }
      } catch (err) {
        setloading(false)
        if (axios.isAxiosError(err)) {
          // Accedi in modo sicuro alla proprietà response
          console.log(err.response?.data?.message || "Errore sconosciuto");
          seterroremess(err.response?.data?.message);
        } else {
          console.log("Errore non riconosciuto:", err);
        }
    seterrore(true);
      }
    };


  const handleinputchange = (event: React.ChangeEvent<HTMLInputElement>)  => {
    const {name, value} = event.target;
  console.log(value)
    setInputValue({
      ...inputValue,
      [name]: value
    });
  }

  return (
    <div className='flex flex-col h-screen overflow-auto bg-angular-gradient scrollbar-custom' >
    <header className="text-[1.3rem]  text-[#ECC5A0] font-imperial p-2">
    <div className='w-[30%] hidden sm:flex'>
      Giangibuilds
      </div>
    </header>
    {/* FRAME_1 */}  <div className='flex flex-col flex-1  items-center '>
   
    {/* RIQUADRO_1 */}     <div className={`w-full flex-col p-2 px-6 font-inter font-light ${isMobile ? 'sm:max-w-[18rem] sm:border-2 sm:border-collapse sm:border-[#6161617a]' : 'max-w-[18rem] border-2 border-collapse border-[#6161617a]'} rounded-[5px]  `}>
     
        {/* REGISTER */}
       <div className={` flex justify-center text-white ${isMobile ? 'text-[3.7rem] sm:text-[2.3rem]' : 'text-[2.3rem]' } `}>
        Register
       </div>
       <div className={` flex flex-col justify-center  text-white ${isMobile ? 'text-[1.2rem] sm:text-[0.8rem]' : 'text-[0.8rem]' }    font-inter font-light `}>
       

          {/* NOME E COGNOME */}
       <div className='flex flex-row mt-3'>
        
        <div className='flex flex-col flex-1 mr-1 mt-1'>
       <div className=' flex justify-start   '>
          Username
        </div>
        
        <input
        type="text"
        style={{
        border: inputValue.nome === "" && errore   ? "2px solid red": "2px solid #6161617a", // Specifica spessore, stile e colore insieme
      }}
        className={`bg-transparent border-2 border-[#6161617a] text-white border-collapse rounded-[5px] p-1 ${isMobile ? 'text-[1rem] sm:text-[0.7rem]' : 'text-[0.7rem]' } `}
        value={inputValue.nome} // Lega l'input allo stato
          name="nome"
        onChange={handleinputchange} // Gestore per il cambiamento dell'input
        placeholder='Ex: REgelly150'
        
      />
        </div>
</div>
        {/* EMAIL INPUT */}
       
        <div className=' flex justify-start mt-1  '>
          Email
        </div>
        <input
        type="text"
        style={{
          border: inputValue.email === "" && errore   ? "2px solid red": "2px solid #6161617a", // Specifica spessore, stile e colore insieme
        }}
        className={`bg-transparent border-2 border-[#6161617a] text-white border-collapse rounded-[5px] p-1 ${isMobile ? 'text-[1rem] sm:text-[0.7rem]' : 'text-[0.7rem]' } `}
        value={inputValue.email} // Lega l'input allo stato
          name="email"
        onChange={handleinputchange} // Gestore per il cambiamento dell'input
        placeholder='Ex: REgelly150@gmail.com'
      />
     
     {/* PASSWORD INPUT */}
     <div className=' flex justify-start mt-1 '>
          Password
        </div>
        <input
        type="Password"
        style={{
          border: inputValue.password === "" && errore   ? "2px solid red": "2px solid #6161617a", // Specifica spessore, stile e colore insieme
        }}
        className={`bg-transparent border-2 border-[#6161617a] text-white border-collapse rounded-[5px] p-1 ${isMobile ? 'text-[1rem] sm:text-[0.7rem]' : 'text-[0.7rem]' } `}
        value={inputValue.password} // Lega l'input allo stato
        name="password"
        onChange={handleinputchange} // Gestore per il cambiamento dell'input
        placeholder='Ex: improplayer12!'
      />
  {/* PASSWORD INPUT */}
  <div className=' flex justify-start mt-1 '>
          Confirm password
        </div>
        <input
        type="Password"
        style={{
          border: inputValue.password_confirm === "" && errore   ? "2px solid red": "2px solid #6161617a", // Specifica spessore, stile e colore insieme
        }}
        className={`bg-transparent border-2 border-[#6161617a] text-white border-collapse rounded-[5px] p-1 ${isMobile ? 'text-[1rem] sm:text-[0.7rem]' : 'text-[0.7rem]' } `}
        value={inputValue.password_confirm} // Lega l'input allo stato
        name="password_confirm"
        placeholder='confirm your password'
        onChange={handleinputchange} // Gestore per il cambiamento dell'input
  
      />
       {/* registrati */}
     <div className='flex flex-col justify-center mt-2 p-3'>
      {
        loading ? 
       
        <div  className={`flex border-[1px] border-[#5e60ce]  rounded-[10px] ${isMobile ? 'w-[100%]' : 'w-[13rem]' }  p-1 justify-center   `}>
        <div className="loader"></div>
        </div>
:
<button onClick={handleRegister} className={`flex border-[1px] border-[#5e60ce]  rounded-[10px] ${isMobile ? 'w-[100%]' : 'w-[13rem]' }  p-1 justify-center   `}>
Register
</button>
      }
   
      { erroremess? 
      <div className={` flex flex-col pt-2 justify-start  ${isMobile ? 'text-[0.85rem] sm:text-[0.5rem]' : 'text-[0.5rem]' } text-[#ca2d2d] text-center `}>{erroremess}</div>
      :
<div></div>
      }
     </div>
     <div className={` flex flex-col justify-start mb-3 ${isMobile ? 'text-[0.85rem] sm:text-[0.5rem]' : 'text-[0.5rem]' } text-[#a5a5a5] text-center `}>
     Registering on the site will mean that you have read and accepted our <a className='text-[#2e2aff]' href='http://localhost:3000/terms'>Terms of service and privacy</a>
        </div>
       {/* Dividi */}
       <div className='flex space-x-3 items-center '>
   <div className='flex flex-1 bg-[#999999c7] h-[1px] '></div>
   <div className='flex  rounded-full  border-[1px] border-[#999999c7] h-[1rem] w-[1rem] '></div>
   <div className='flex flex-1 bg-[#999999c7] h-[1px] '></div>
   
     </div>

       {/* Possiedi già un account? */}
       <div className='flex  justify-center text-[0.8rem]  p-3 '>
    You already have an account?
     </div>
        {/* Registrati */}
        <div className='flex justify-center px-3  '>
     <button onClick={goToAboutPage} className={`flex border-[1px] border-[#c29a50ce]  rounded-[10px] ${isMobile ? 'w-[100%]' : 'w-[13rem]' }  p-1 justify-center   `}>
      Login
      </button>
     </div>
       </div>
     </div>
      
  {/* RIQUADRO_2 */}   
    </div>
    {/* FRAME_2 */}
    <div className='flex justify-center mt-2 text-white text-[0.6rem] '>
    © 2025 Giangibuilds from Gianluigi Izzo
    </div>
    
    </div>
  );
}

export default Register;
