import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator  } from "firebase/auth"; // Facoltativo: Se usi Firebase Authentication
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore"; // Facoltativo: Se usi Firestore
import { getStorage, connectStorageEmulator } from "firebase/storage"; // Facoltativo: Se usi Firebase Storage

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

// Esporta i servizi che ti servono
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);

if (process.env.NODE_ENV === 'development') {
  // Connetti Firestore all'emulatore
  connectFirestoreEmulator(firestore, 'localhost', 8080); // Porta predefinita per Firestore
  connectStorageEmulator(storage, 'localhost', 9199); // Porta predefinita per Storage
  connectAuthEmulator(auth, 'http://localhost:9099'); // Porta predefinita per Authentication
}