import React, {useEffect, useState, useRef} from 'react';
import {Firestore, query, orderBy, limit, Timestamp, collection, where ,doc, getDocs, startAfter ,Query, onSnapshot, QueryDocumentSnapshot, DocumentData} from 'firebase/firestore'
import '../styles.css';
import { firestore } from '../FirebaseConfig'; 
import '../App.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { auth } from '../FirebaseConfig';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { storage } from "../FirebaseConfig";
import santa from '../images/Santa.png'; // Importa l'immagine
import Product from '../objects/Model';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { useParams } from 'react-router-dom';
interface Model {
  id: string;
  nome: string;
  descrizione: string;
  immagine: string;
  Acquisti: number;
  prezzo: number;
  Categoria: string;
  StoragePath: string;
  upload: Date;
  
}

const Buy: React.FC = () => {

  const navigate = useNavigate();
  const location = useLocation();
 const { Category, Range ,Sort, Page} = useParams();
  const [modelsDATA, setModelsDATA] = useState<Model[]>([]);
  const [models, setModels] = useState<Model[]>([]);
  const [totalmodels, settotalModels] = useState(0);
  const [save, setsave] = useState(false);
  const nmp = 20;
  const Paginattuale = Number(Page) - 1;
  const hasFetched = useRef(false);
  const [loading, setloading] = useState(false); // Tipizza lastVisible come QueryDocumentSnapshot<DocumentData> o null
  const [minPrice, maxPrice] = Range?.includes("-")
  ? Range.split("-").map(Number) // Se sì, splitta e converte in numeri
  : [0, Infinity]; // Se no, assegna valori di default
  const [inputValue, setInputValue] = useState({
    Categoria: Category 
  ? (Category.includes("All") ? "All" : Category)
  : "NULL",
    MinandMax: Range?.includes("-")? minPrice+"-"+maxPrice : "All",
    Sortorder: Sort?.includes("Recent")? "Recent": "Alfabethic",
  });
  const logout =  async ()  => {
    signOut(auth);
    }
    const handleinputchange = async (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>)  => {
      const {name, value} = event.target;

setInputValue({
        ...inputValue,
        [name]: value
      });

    
  }
//getmodels
useEffect(() => {
  const fetchModels = async () => {
    console.log("ricarico")
    try {
      setloading(false);
      const ModelsCollection = query(
        collection(firestore, "Modelli"),
        where("Disponibilità", "==", true)
      );
      const snapshot = await getDocs(ModelsCollection);
      const allModels = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            nome: data?.Nome || "Nome non disponibile",
            descrizione: data?.Descrizione || "Descrizione non disponibile",
            immagine: "",
            Acquisti: data?.Acquisti || 0,
            prezzo: data?.Prezzo || 0,
            Categoria: data?.Categoria || "Categoria non disponibile",
            StoragePath: data?.Storagepath || "Path non disponibile",
            upload: data?.upload?.toDate() || null,
          };
        })
      );

      setModelsDATA(allModels);
      setloading(true);
    } catch (error) {
      console.error("Errore nel recupero dei modelli:", error);
      setloading(true);
    }
  };

  if (!hasFetched.current) {
    hasFetched.current = true;
    fetchModels();
  }
}, []);

useEffect(() => {
  if (inputValue.Categoria === Category && inputValue.MinandMax === Range && inputValue.Sortorder === Sort) {
    setsave(false); // Disabilita il salvataggio se i valori sono invariati
  } else{
    setsave(true)
  }
}, [inputValue, location]);







useEffect(() => {
  setloading(false);
  const fetchData = async () => {
    
    const sortedModels = [...modelsDATA].sort((a, b) => {
      if (inputValue.Sortorder === "Alfabethic") {
        return a.nome.localeCompare(b.nome);
      }
      return b.upload.getTime() - a.upload.getTime(); // Usa getTime() per chiarezza
    });
    const filteredDataPromises = sortedModels
      .filter(model => {
        const matchesCategoria = inputValue.Categoria === "All" || model.Categoria.includes(inputValue.Categoria);
        const matchesPrezzo    = inputValue.MinandMax === "All" || (model.prezzo >= minPrice && model.prezzo <= maxPrice);
        return matchesCategoria && matchesPrezzo;
      })
      .slice(Number(Page) * nmp - nmp, Number(Page) * nmp) // Paginazione ottimizzata
      .map(async model => {
        const imageRef = ref(storage, `Modelli/${model.id}/${model.id}.jpg`);
        model.immagine = await getDownloadURL(imageRef);
        return model;
      });

    const filteredData = await Promise.all(filteredDataPromises);

    const totalModelsCount = [...modelsDATA].filter(model => {
      const matchesCategoria = inputValue.Categoria === "All" || model.Categoria.includes(inputValue.Categoria);
      const matchesPrezzo    = inputValue.MinandMax === "All" || (model.prezzo >= minPrice && model.prezzo <= maxPrice);
      return matchesCategoria && matchesPrezzo;
    }).length;

    setModels(filteredData);
    settotalModels(totalModelsCount);
    setloading(true);
  };

  fetchData();
}, [modelsDATA, location]);





















const more = () => {
if((totalmodels - (( Paginattuale + 1)*nmp)) > 0 ) {
  return true
}else{
  return false
}
}

const less = () => {
if ( Paginattuale <= 0) {
  return false
}else{
  return true
}
}


const saveoptions = () => {
navigate(`/BuyModels/${inputValue.Categoria}/${inputValue.MinandMax}/${inputValue.Sortorder}/1`)
}

  return (
    <div className='flex flex-col h-screen  overflow-auto bg-home2  scrollbar-custom '  >
    <header className="flex     text-[1.3rem]  text-[#ECC5A0] font-imperial p-3 ">
    <div className='w-[30%] hidden sm:flex'>
      Giangibuilds
      </div>
             <div className='flex flex-1 items-center justify-center   text-[#8b8b8b] text-[0.7rem] space-x-3  '>
             <Link className='flex    justify-center     font-inter ' to="/">
              
              Home
                 
            
                   
                  
                  </Link>
              
                  <Link className=' flex  justify-center     font-inter ' to="/Buy">
                   
                   Buy <span>&nbsp;</span><p className='hidden md:flex'> Models</p>
                      
                 
                        
                       
                       </Link>
                       <Link className=' flex justify-center     font-inter ' to="/Cronology">
                   Cronology <span>&nbsp;</span> <p className='hidden md:flex'>boughts</p>
                       </Link>
                       <Link className='flex  justify-center     font-inter ' to="/terms">
       Terms
                       </Link>
                  </div>
           <div className='flex  sm:w-[30%] font-inter text-[0.8rem] items-center justify-end'>
          
               {auth.currentUser?.displayName}
               
               <div className='flex  font-inter '>
             <button onClick={logout} className='bg-[#A47040] ml-2 text-[0.7rem] py-1 text-white px-1 rounded-[5px] '>
               Log out
               </button>
           </div>
           </div>
           </header>
  
    
     
     
 
   
        {/* Parte iniziale */}
 
     <div className='flex flex-col px-[1rem] sm:px-[4.5rem] pt-5 '>
<div className='flex  flex-row'>
 
<div className='flex flex-1 space-x-3 mt-2'>
<div className='flex flex-col justify-end'>
      <h3 className='text-white text-sm'>Category</h3>
      <select  className='border-2 p-1 text-white text-[0.7rem] custom-select border-[#6161617a] rounded-[5px] bg-transparent'  value={inputValue.Categoria} name={"Categoria"} onChange={handleinputchange}>
      <option className='bg-transparent text-black' value="All">All categories</option>
        <option className='bg-transparent text-black' value="Roblox Builds">Roblox builds</option>
        <option className='bg-transparent text-black' value="Roblox Scripts">Roblox scripts</option>
        <option className='bg-transparent text-black' value="Roblox Maps">Roblox maps</option>
        <option className='bg-transparent text-black' value="Roblox Meshes">roblox meshes</option>
        <option className='bg-transparent text-black' value="Roblox Cars">roblox cars</option>
        
      </select>
      </div>
      <div className='flex flex-col justify-end'>
      <h3 className='text-white text-sm'>Price range</h3>
      <select  className='border-2 p-1 custom-select  text-white text-[0.7rem] border-[#6161617a] rounded-[5px] bg-transparent'  value={inputValue.MinandMax} name={"MinandMax"} onChange={handleinputchange}>
      <option className='bg-transparent text-black' value="All">All ranges</option>
        <option className='bg-transparent text-black' value="0-10">0€ to 10€</option>
        <option className='bg-transparent text-black' value="10-50">10€ to 50€</option>
        <option className='bg-transparent text-black' value="50-100">50€ to 100€</option>
        <option className='bg-transparent text-black' value="100-200">100€ to 200€</option>
        <option className='bg-transparent text-black' value="200-300">200€ to 300€</option>
        <option className='bg-transparent text-black' value="300-500">300€ to 500€</option>
        <option className='bg-transparent text-black' value="500-1000">500€ to 1000€</option>
      </select>
      </div>
      <div className='flex flex-col justify-end'>
      <h3 className='text-white text-sm'>Sort options</h3>
      <select  className='border-2 p-1 custom-select  text-white text-[0.7rem] border-[#6161617a] rounded-[5px] bg-transparent'  value={inputValue.Sortorder} name={"Sortorder"} onChange={handleinputchange}>
       
        <option className='bg-transparent text-black' value="Alfabethic">Alfabethic</option>
        <option className='bg-transparent text-black' value="Recent">Most recent</option>

        
      </select>
      </div>
      {save? 
   <div className='flex items-end'>
   <button onClick={() => saveoptions()} className='border-2 p-1 text-white text-[0.7rem] px-4 border-[#35d12700] rounded-[5px] bg-[#3988269d]'>Apply</button>
   </div>
      :
      <div></div>
    }
   
     
      </div>
    
</div>
{!loading ? (
  <div className='flex flex-1 justify-center items-center pt-32'>
  <div className="loader2"></div>
  </div>
) : models.length > 0 ? (
  <div className="grid justify-items-center grid-custom-cols-2 grid-custom-cols-3 grid-custom-cols-4 grid-custom-cols-5 grid-custom-cols-6 gap-x-[2px]">
    {models.map((model) => (
      <Product
        key={model.id} // Usare una chiave univoca per ogni elemento in lista
        name={model.nome}
        description={model.descrizione}
        image={model.immagine}
        Acquisti={model.Acquisti}
        prezzo={model.prezzo}
        id={model.id}
        path={model.StoragePath}
      />
    ))}
  </div>
) : (
  <div className="flex pt-32 flex-col flex-1 justify-center items-center text-[#cecece] text-[1.3rem]">
    Nothing to show here...
  </div>
)}





     </div>
     <div className='flex flex-1 flex-col  justify-end'>
     <div className='flex  flex-row justify-center pt-3'>
<button style={{color: less()? "white" : "#6161617a"}} onClick={Number(Page) > 1 ? () => navigate(`/BuyModels/${Category}/${Range}/${Sort}/${Number(Page) - 1}`) : undefined}    className='text-white bg-transparent px-2   text-center rounded-full'>&lt;</button>
  <button className='text-white bg-transparent px-2 border-2 border-orange-300  text-center rounded-full'>{ Number(Page) >= 1?  Number(Page) : "error"}</button>
  <button style={{color: more()? "white" : "#6161617a"}} onClick={more()? () => navigate(`/BuyModels/${Category}/${Range}/${Sort}/${Number(Page) + 1}`): undefined}  className=' bg-transparent px-2   text-center rounded-full'> &gt;</button>
</div>
     <div className='flex justify-center mt-2 text-white text-[0.6rem] '>
     © 2025 Giangibuilds from Gianluigi Izzo
    </div>
    </div>
    </div>
  );
}

export default Buy;


