// src/App.tsx
import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import Login from './screens/Login';
import BuyModels from './screens/BuyModels';
import Register from './screens/Register';
import Home from './screens/Home';
import More from './screens/More';
import Passwordforgot from './screens/Passwordforgot';
import Buy from './screens/Buy';
import Cronologia from './screens/cronology';
import Tos from './screens/Tos';
import { auth } from './FirebaseConfig';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import VerifyEmail from "./screens/VerifyEmail";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPECLIENT? process.env.REACT_APP_STRIPECLIENT: "");


const App: React.FC = () => {
  const [logged, setlogged] = useState(false);
  const [completed, setcompleted] = useState(false);
   const auth = getAuth();
  const user = auth.currentUser;
  
  useEffect(() => {
    
    // Verifica che Firebase Authentication sia attivo e monitori lo stato di autenticazione
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setcompleted(false)
      setlogged(false)
      if (user) {
        setlogged(true)
        setcompleted(true)
      } else {
        setcompleted(true)
        setlogged(false)
      }
    });

    return () => unsubscribe(); // Cleanup per evitare memory leak
  }, [user?.emailVerified]);

  if (completed == false) {
    return null
  }



    return  (
      <Router>
        <Routes>
          {user ? (
            user.emailVerified ? (
              <>
                {/* Utente autenticato e verificato */}
                <Route path="/" element={<Home />} />
                <Route
                  path="/articles/:articleId"
                  element={
                    <Elements stripe={stripePromise}>
                      <More />
                    </Elements>
                  }
                />
                <Route path="/buy" element={<Buy />} />
                <Route path="/BuyModels/:Category/:Range/:Sort/:Page" element={<BuyModels />} />
                <Route path="/cronology" element={<Cronologia />} />
                <Route path="/terms" element={<Tos />} />
                <Route path="/register" element={<Navigate to="/" />} />
                <Route path="/verify-email" element={<Navigate to="/" />} />
                <Route path="/passwordforgotten" element={<Navigate to="/" />} />
              </>
            ) : (
              <>
                {/* Utente autenticato ma email non verificata */}
                <Route path="/" element={<Navigate to="/verify-email" />} />
                <Route path="/verify-email" element={<VerifyEmail />} />
                <Route path="/" element={<Navigate to="/verify-email" />} />
                <Route path="/register" element={<Navigate to="/verify-email" />} />
              </>
            )
          ) : (
            <>
              {/* Utente non autenticato */}
              <Route path="/" element={<Login />} />
              <Route path="/Buy" element={<Navigate to="/" />} />
              <Route path="/Cronology" element={<Navigate to="/" />} />
              <Route path="/Home" element={<Navigate to="/" />} />
              <Route path="/verify-email" element={<Navigate to="/" />} />
              <Route path="/articles/:articleId" element={<Navigate to="/" />} />
              <Route path="/BuyModels/:Category/:Range/:Sort/:Page" element={<Navigate to="/" />} />
              <Route path="/register" element={<Register />} />
              <Route path="/terms" element={<Tos />} />
              <Route path="/passwordforgotten" element={<Passwordforgot />} />
            </>
          )}
        </Routes>
      </Router>
    );
};

export default App;
