import React , { useEffect, useState } from "react";
import { getAuth, sendEmailVerification, sendPasswordResetEmail  } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
const VerifyEmail: React.FC = () => {
  const auth = getAuth();
  const user = auth.currentUser;
  const [loading, setLoading] = useState(true);
  const [email, setemail] = useState("");
    const navigate = useNavigate();

  const sendResetPasswordEmail = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      console.log('Email di reset della password inviata!');
      // Puoi aggiungere un messaggio di conferma all'utente
    } catch (error) {
      console.error('Errore durante l\'invio dell\'email:');
      // Puoi gestire gli errori (es. email non trovata)
    }
  };



  return (
    <div className='flex flex-col h-screen overflow-auto items-center bg-angular-gradient scrollbar-custom justify-center  '>
      <div className="flex flex-col mb-10 items-center ">
      <h1 className="flex text-[2.5rem] p-2 text-white">Reset Password</h1>

        <input
        type="text"
        className={`bg-transparent border-2 w-[14rem] border-[#6161617a] text-[0.7rem] text-white border-collapse rounded-[5px] p-1`}
        value={email} // Lega l'input allo stato
        onChange={(e) => setemail(e.target.value)} // Gestore per il cambiamento dell'input
        placeholder='Insert your email'
      />
      <p  className="flex text-[0.6rem] p-2 w-[18rem]  text-white">Click "Send email" to send a password reset link to the email. Remember, this email must have been used to log in at least once.</p>
     <div className="flex flex-row mt-3 space-x-2">
      <button className="flex text-white border-2 px-2  rounded-[10px]" onClick={sendResetPasswordEmail}>Send email</button>

      <button onClick={() => navigate("/login")} className='bg-[#A47040]  text-[0.7rem] py-1 text-white px-1 rounded-[5px] '>
        Back to log in
        </button>
        </div>
        </div>
    </div>
  );
};

export default VerifyEmail;
