import React, {useEffect, useState} from 'react';
import '../styles.css';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import { auth } from '../FirebaseConfig';
import { isMobile } from 'react-device-detect';
import google from "../images/google.png"
import { onAuthStateChanged, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, fetchSignInMethodsForEmail } from 'firebase/auth';
function Login() {
  const navigate = useNavigate();
  const [logged, setlogged] = useState(false);
  const [erroremess, seterroremess] = useState("")
  const goToAboutPage = () => {
    navigate('/register');
  };

 
  
  console.log(isMobile)
  useEffect(() => {
    // Verifica che Firebase Authentication sia attivo e monitori lo stato di autenticazione
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log('Utente loggato:', user);
      } else {
        console.log('Nessun utente loggato');
      }
    });

    return () => unsubscribe(); // Cleanup per evitare memory leak
  }, []);


  const [inputValue, setInputValue] = useState({
    email: "",
    password: "",
  });
  
  const handleLogin = async (e: React.FormEvent) => {
    
    e.preventDefault();

    try {
  
      await signInWithEmailAndPassword(auth, inputValue.email, inputValue.password);
      navigate('/');
    } 
    
    catch (error: any) {
      // Imposta un messaggio d'errore in caso di login fallito
   
        
     
      if (error.code == "auth/invalid-email") {
        seterroremess("L'email is not valid")
      }
      else if(error.code == "auth/invalid-credential" || error.code == "auth/missing-password" ) {
   
        seterroremess("Password is incorrect")
      }

      console.error("Errore di autenticazione:", error.message);
    }
  };

  const handleinputchange = (event: React.ChangeEvent<HTMLInputElement>)  => {
    const {name, value} = event.target;
  console.log(value)
    setInputValue({
      ...inputValue,
      [name]: value
    });
  }





  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      // Ottieni le informazioni dell'utente
      const user = result.user;
      console.log("Utente loggato:", user);
    } catch (error) {
      console.error("Errore durante il login con Google:", error);
    }
  };




  if (logged == true) {
    return (
      <div>request failed: already logged in</div>
    )
   
    }

  return (
    <div className='flex flex-col h-screen overflow-auto bg-angular-gradient scrollbar-custom  ' >
    <header className="text-[1.3rem] z text-[#ECC5A0] font-imperial p-2">
    <div className='w-[30%] hidden sm:flex'>
      Giangibuilds
      </div>
    </header>
    {/* FRAME_1 */} 
    <div className='flex flex-1 justify-center items-center pb-10'>
     <div className='flex flex-1 sm:flex-row flex-col  justify-center' >
   

    <div className=' flex-col items-start justify-start pt-6 p-3 hidden md:flex    '>
  <div>
  <p className=' text-[2.5rem]  leading-[2.4rem] font-semibold  pl-0   sm:pl-[4rem]  font-inter m-0 p-0 text-[#E4D2D2]'>
   Welcome to
  </p>
 
  <p className='text-[3rem] leading-[3.7rem] font-semibold   font-inter    pl-0   sm:pl-[4rem] text-[#ECC5A0]'>
  Giangibuilds
  </p>
  <p className='text-[0.6rem] sm:pr-0  pr-10 pl-10  py-2  font-inter  max-w-[25rem] sm:text-left  text-center    sm:pl-[4rem] text-[#A39C96]'>
  Giangibuilds is a platform that started with the idea of offering various services, such as the purchase of 3D models, scripts, and future features like bot creation and management. By logging in or registering, users automatically agree to the terms and conditions of the application.  </p>
 
  <div className='flex   sm:pl-[4rem] pt-5 space-x-3 '>
   <a href='mailto:support@giangibuilds.com?subject=%5BInsert%20object%20of%20discussion%5D&body=%5BTell%20us%20what%20you%20need!%5D' className='bg-[#A47040] rounded-[6px] text-[0.7rem] text-center text-white w-[7rem] py-[0.4rem]' >Contact me</a>
   <button onClick={() => navigate("/terms")} className='bg-transparent  rounded-[6px] text-[0.7rem] text-white  w-[7rem] py-[0.3rem] border-[1px] border-[#A7A7A7]'>Our Terms</button>
  </div>
  </div>
  </div>





<div className='flex  flex-col   justify-start items-center pr-0 sm:pr-16 '>
  {/* RIQUADRO_1 */}     <div className={`w-full flex-col p-2 px-6 font-inter font-light ${isMobile ? 'sm:max-w-[18rem] sm:border-2 sm:border-collapse sm:border-[#6161617a]' : 'max-w-[18rem] border-2 border-collapse border-[#6161617a]'} rounded-[5px]  `}>
        {/* LOGIN */}
       <div className={` flex justify-center text-white ${isMobile ? 'text-[3.7rem] sm:text-[2.3rem]' : 'text-[2.3rem]' } `}>
        Login
       </div>
       <div className={` flex flex-col justify-center  text-white ${isMobile ? 'text-[1.2rem] sm:text-[0.8rem]' : 'text-[0.8rem]' }    font-inter font-light `}>
       
        {/* EMAIL INPUT */}
       
        <div className=' flex justify-start pt-4  '>
          Email
        </div>
        <input
        type="text"
        className={`bg-transparent border-2 border-[#6161617a] text-white border-collapse rounded-[5px]  p-1 ${isMobile ? 'text-[1rem]   sm:text-[0.7rem]' : ' w-[14rem] text-[0.7rem]' } `}
        value={inputValue.email} // Lega l'input allo stato
          name="email"
        onChange={handleinputchange} // Gestore per il cambiamento dell'input
        placeholder='Ex: REgelly150@gmail.com'
      />
     
     {/* PASSWORD INPUT */}
     <div className=' flex justify-start pt-2  '>
          Password
        </div>
        <input
        type="Password"
        className={`bg-transparent border-2 border-[#6161617a] text-white border-collapse rounded-[5px] p-1 ${isMobile ? 'text-[1rem] sm:text-[0.7rem] ' : ' w-[14rem] text-[0.7rem]' } `}
        value={inputValue.password} // Lega l'input allo stato
        name="password"
        onChange={handleinputchange} // Gestore per il cambiamento dell'input
        placeholder='Ex: improplayer12!'
        
      />

       {/* Accedi */}
     <div className='flex flex-col justify-center items-center mt-5  p-2'>
     <button onClick={handleLogin} className={`flex border-[1px] border-[#ffffffb0]  rounded-[10px] ${isMobile ? 'w-[100%] sm:max-w-[13rem]' : 'w-[13rem]' }  p-1 justify-center   `}>
      Login
      </button>

      <button onClick={signInWithGoogle} className={`flex items-center border-[1px] mt-2 border-[#bebebeb0]  rounded-[10px] ${isMobile ? 'w-[100%]' : 'w-[13rem]' }  p-1 justify-center   `} > <img src={google} className='w-4 mr-1' ></img>Login with Google</button>
    
      { erroremess? 
      <div className='flex flex-col items-center'>
      <div className={` flex flex-col pt-2 justify-start  ${isMobile ? 'text-[0.85rem] sm:text-[0.5rem]' : 'text-[0.5rem]' } text-[#ca2d2d] text-center `}>{erroremess}</div>
    <div className='flex flex-col items-center'>
      <div className='flex text-[0.5rem] pt-3'>Forgot your password?</div>
      <div onClick={() => navigate("/passwordforgotten")} className='flex text-[0.5rem] text-blue-600'>Create a new one</div>
    </div>
      </div>
      :
<div></div>
      }
     </div>
       {/* Dividi */}
       <div className='flex space-x-3 items-center '>
   <div className='flex flex-1 bg-[#999999c7] h-[1px] '></div>
   <div className='flex  rounded-full  border-[1px] border-[#999999c7] h-[1rem] w-[1rem] '></div>
   <div className='flex flex-1 bg-[#999999c7] h-[1px] '></div>
   
     </div>

       {/* Possiedi già un account? */}
       <div className={`flex  justify-center  p-1 mt-4 ${isMobile ? 'text-[1rem] sm:text-[0.7rem]' : 'text-[0.7rem]' }`}>
     You still don't have an account?
     </div>
        {/* Registrati */}
        <div className='flex  justify-center  p-2'>
     <button onClick={goToAboutPage} className={`flex border-[1px] border-[#dfa53bb0]  rounded-[10px] ${isMobile ? 'w-[100%]' : 'w-[13rem]' }  p-1 justify-center   `}>
      Register
      </button>
      
     </div>
       </div>
     </div>
      

    </div>
    {/* FRAME_2 */}
    
    </div>
    </div>
    <div className='flex justify-center mt-2 text-white text-[0.6rem] '>
    © 2025 Giangibuilds from Gianluigi Izzo
    </div>
    
    </div>
  );
}

export default Login;
