import React , { useEffect, useState } from "react";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { onAuthStateChanged, signOut } from 'firebase/auth';
const VerifyEmail: React.FC = () => {
  const auth = getAuth();
  const user = auth.currentUser;
  const [loading, setLoading] = useState(true);


  const checkEmailVerification = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      try {
        await user.reload(); // Aggiorna lo stato dell'utente
        if (user.emailVerified) {
          window.location.reload()
        }
      } catch (error) {
        console.error("Error reloading user:", error);
      }
    } else {
      console.warn("No user logged in.");
    }
    setLoading(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkEmailVerification();
    }, 5000); // Controlla ogni 5 secondi

    // Cleanup per evitare memory leak
    return () => clearInterval(interval);
  }, []);


  const logout =  async ()  => {

    signOut(auth);
    }



  const resendVerificationEmail = async () => {

  

    if (user) {
      try {
        await sendEmailVerification(user); // Metodo corretto
      } catch (error) {
        console.error("Errore durante l'invio dell'email di verifica:", error);
      }
    }
  };

  return (
    <div className='flex flex-col h-screen overflow-auto items-center bg-angular-gradient scrollbar-custom  '>
      <h1 className="flex text-[3rem] p-2 text-white">Verify your email</h1>
      <p  className="flex text-[0.8rem] p-2 text-white">Click this button for send a verification email to {user?.email}.After clicking the button, please check your inbox and verify.</p>
      <button className="flex text-white border-2 px-2 mt-10 rounded-[10px]" onClick={resendVerificationEmail}>Send email</button>
      <div>Or</div>
      <button onClick={logout} className='bg-[#A47040] ml-2 text-[0.7rem] py-1 text-white px-1 rounded-[5px] '>
        Log out
        </button>
    </div>
  );
};

export default VerifyEmail;
