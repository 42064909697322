import React, {useEffect, useState} from 'react';
import {Firestore, query, orderBy, limit, Timestamp, collection, where, doc, getDocs, startAfter , onSnapshot, QueryDocumentSnapshot, DocumentData} from 'firebase/firestore'
import '../styles.css';
import { firestore } from '../FirebaseConfig'; 
import '../App.css';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { auth } from '../FirebaseConfig';
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../FirebaseConfig";
import santa from '../images/Santa.png'; // Importa l'immagine
import Product from '../objects/Model';
import { signOut } from 'firebase/auth';

interface Model {
  id: string;
  nome: string;
  descrizione: string;
  immagine: string;
  Acquisti: number;
  prezzo: number;
  Categoria: string;
  StoragePath: string;
  upload: Date;
  
}

const Buy: React.FC = () => {

  const navigate = useNavigate();


  const [models, setModels] = useState<Model[]>([]);
  const goToAboutPage = () => {
    navigate('/terms');
  };
 
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<DocumentData> | null>(null); // Tipizza lastVisible come QueryDocumentSnapshot<DocumentData> o null




  


  const logout =  async ()  => {
    signOut(auth);
    }


//getmodels
useEffect(() => {
  const ModelsCollection = collection(firestore, "Modelli");
  let modelsQuery = query(
    ModelsCollection, // Filtro per categoria
    orderBy("upload", "desc"), // Sostituisci "nomeModello" con il campo che contiene il nome del modello
    limit(5) // Limita ai primi 2 modelli
  );

 
  // Ascolta i cambiamenti in tempo reale nella collezione "Modelli"
  const unsubscribe = onSnapshot(modelsQuery, async (snapshot) => {
    const modelsData = await Promise.all(
      snapshot.docs.map(async (doc) => {
        const imageRef = ref(storage, `Modelli/${doc.id}/${doc.id}.jpg`);
        const imageUrl = await getDownloadURL(imageRef);

        // Restituisci i dati del modello, compreso l'ID, nome, descrizione e l'URL dell'immagine
        return {
          id: doc.id,
          nome: doc.data()?.Nome || "Nome non disponibile",
          descrizione: doc.data()?.Descrizione || "Descrizione non disponibile",
          immagine: imageUrl,
          Acquisti: doc.data().Acquisti || 0,
          prezzo: doc.data()?.Prezzo || 0,
          Categoria: doc.data()?.Categoria || "Categoria non disponibile",
          StoragePath: doc.data()?.Storagepath || "Path non disponibile",
          upload: doc.data().upload?.toDate() || null,
        };
      })
    );

    // Aggiorna lo stato con i dati ottenuti
    setModels(modelsData);
    setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
  }, (error) => {
    console.error("Errore nell'ascoltare i modelli:", error);
  });

  // Cleanup: chiude la connessione quando il componente viene smontato
  return () => unsubscribe();
}, []); // Si aggiorna solo una volta all'inizio


  




  return (
    <div className='flex flex-col h-screen  overflow-auto bg-home2  scrollbar-custom '  >
     <header className="flex     text-[1.3rem]  text-[#ECC5A0] font-imperial p-3 ">
     <div className='w-[30%] hidden sm:flex'>
      Giangibuilds
      </div>
          <div className='flex flex-1 items-center justify-center   text-[#8b8b8b] text-[0.7rem] space-x-3  '>
          <Link className='flex    justify-center     font-inter ' to="/">
           
           Home
              
         
                
               
               </Link>
           
               <Link className=' flex text-[#ffffff] justify-center     font-inter ' to="/Buy">
                
                Buy <span>&nbsp;</span><p className='hidden md:flex'> Models</p>
                   
              
                     
                    
                    </Link>
                    <Link className=' flex justify-center     font-inter ' to="/Cronology">
                Cronology <span>&nbsp;</span> <p className='hidden md:flex'>boughts</p>
                    </Link>
                    <Link className='flex  justify-center     font-inter ' to="/terms">
    Terms
                    </Link>
               </div>
        <div className='flex text-[#ECC5A0]  sm:w-[30%] font-inter text-[0.8rem] items-center justify-end'>
       
            {auth.currentUser?.displayName}
            
            <div className='flex  font-inter '>
          <button onClick={logout} className='bg-[#A47040] ml-2 text-[0.7rem] py-1 text-white px-1 rounded-[5px] '>
            Log out
            </button>
        </div>
        </div>
        </header>
  
    
     
     
 
   
        {/* Parte iniziale */}
       
        <div className='flex justify-center flex-col md:flex-row md:items-start  items-center    '>
      
        <div className=' flex-col  ml-0  md:ml-[64px] items-center md:items-start  flex   pt-8   '>
   
          
  <p className=' text-[2rem]  leading-[2.3rem] font-semibold     font-inter m-0 p-0 text-[#E4D2D2]'>
Enjoy the most
  </p>

  
  <p className='text-[2.2rem] leading-[2.3rem] font-semibold  py-2   font-inter     text-[#ECC5A0]'>
 Beautiful Models
  </p>
  <p className='text-[0.6rem] px-10   md:pr-0 md:pl-0   md:text-left  text-center font-inter  max-w-[30rem]    text-[#A39C96]'>
  “ In this section of the website, you can view our products and decide whether to purchase them. To buy one of our products, simply click the "More" button and proceed to checkout. The payment methods we accept are Stripe (credit/debit card) and PayPal. “   
  </p>
 
  <div className='flex  pt-5 space-x-3'>
  <button onClick={() => navigate("/BuyModels/All/All/Recent/1")} className='bg-[#A47040] rounded-[6px] text-[0.7rem] text-center text-white w-[7rem] py-[0.4rem]' >See all models</button>
  <button onClick={goToAboutPage} className='bg-transparent  rounded-[6px] text-[0.7rem] text-white  w-[7rem] py-[0.3rem] border-[1px] border-[#A7A7A7]'>Our terms</button>
  </div>
  </div>

       
        <img src={santa} alt="Logo" className='w-[26rem] md:block hidden   brightness-[90%] pr-0 md:pr-[20px]'/> {/* Inserisci l'immagine */}
      
    
       </div>
     <div className='flex flex-col px-[1rem] sm:px-[4.5rem]  '>
     <p className='text-[1.7rem]    font-normal     text-[#ffffff]'>
   Recent Models
  </p>
<div className='grid justify-items-center   grid-custom-cols-2 grid-custom-cols-3 grid-custom-cols-4 grid-custom-cols-5 grid-custom-cols-6 gap-x-[2px]  '>
{models.map((model) => (
  // Verifica se il prezzo del modello è maggiore di 30

    <Product
      key={model.id} // Usare una chiave univoca per ogni elemento in lista
      name={model.nome}
      description={model.descrizione}
      image={model.immagine}
      Acquisti={model.Acquisti}
      prezzo={model.prezzo}
      id={model.id}
      path={model.StoragePath}
    />

))}


</div>

     </div>
     
     <div className='flex justify-center mt-2 text-white text-[0.6rem] '>
     © 2025 Giangibuilds from Gianluigi Izzo
    </div>
    </div>
  );
}

export default Buy;


